import React from 'react'
import Layout from "../components/layout-v2";
import '../styles/privacy.scss';

const PrivacyPolicy = () => (
    <Layout>
        <title>Welcome to DD Zone</title>
        <div className="privacy-policy">
            <div className="privacy">
            <h2>Privacy Policy</h2>
                <p>
                    <h3>1. Please read carefully</h3>
                    This policy describes our privacy practices – what Personal Information we collect about our Users, what we do
                    with it, how we share it, and your rights regarding that Personal Information. This Privacy Policy applies to all
                    DD Zone Inc users, including unregistered visitors, registered users. Prior to accessing or using our Services,
                    please read this policy and make sure you fully understand our practices in relation to your Personal Information.
                    If you read and fully understand this Privacy Policy, and remain opposed to our practices, you must immediately
                    leave and discontinue all use of any of our Services.
                </p>

                <p>
                    <h3>2. What 'Personal Information' do we collect?</h3>
                    <p>
                        We collect Personal Information you provide us, from your use of the Services, and from other sources. Here are
                        the types of Personal Information we collect about you:
                    </p>
                    <h4>2.1. Information you provide us:</h4>
                    When you register for our Services, purchase and/or register domain names, use any of our Services; and/or when you
                    contact us directly by any communication channel (e.g., DD Zone Inc.'s support tickets, emails), you may provide us
                    Personal Information, such as name, email address, phone number, payment information (for Users with Paid Services),
                    information you include in your communications with us and with other users on our platform, and Personal
                    Information contained in scanned identification documents (such as an ID card, driver's license, passport, or
                    official company registration documents).
                </p>

                <p>
                    <h4>2.2. Information we collect when you use the Services:</h4>
                    When you visit, download, and/or use any of our Services, we may collect aggregated usage Personal Information,
                    such as Visitors' and Users' browsing and 'click-stream' activity on the Services, session heatmaps and scrolls,
                    non-identifying Personal Information regarding the Visitor's or User's device, operating system, internet browser,
                    screen resolution, language and keyboard settings, internet service provider, referring/exit pages, date/time stamps, etc.
                </p>

                <h3>3. Why do we collect such 'Personal Information'?</h3>
                <ul className="personal">
                    <li>To provide and operate the Services</li>
                    <li>To further develop, customize, expand, and improve our Services, based on Users common or personal preferences,
                        experiences and difficulties</li>
                    <li>To provide our Users with ongoing customer assistance and technical support</li>
                    <li>To be able to contact our Users with general or personalized service-related notices and promotional messages</li>
                    <li>To help us to update, expand and analyze our records to identify new customers</li>
                    <li>To analyze our performance and marketing activities</li>
                    <li>To create aggregated statistical data and other aggregated and/or inferred information, which we or our
                        business partners may use to provide and improve our respective services</li>
                    <li>To provide you with professional assistance, only upon your request</li>
                    <li>To enhance our data security and fraud prevention capabilities and</li>
                    <li>To comply with any applicable laws and regulations.</li>
                </ul>

                <h3>4. How we share your 'Personal Information'?</h3>
                We may share your Personal Information with service providers and others (or otherwise allow them access to it) in the
                following manners and instances:

                <p>4.1. Third Party Service Providers:</p>
                <span>
                    DD Zone Inc. has partnered with a number of selected service providers, whose services and solutions complement,
                    facilitate and enhance our own. These include hosting and server co-location services, communications and content
                    delivery networks (CDNs), data and cyber security services, billing and payment processing services, domain name
                    registrars, fraud detection and prevention services, web analytics, e-mail distribution and monitoring services,
                    session recording and remote access services, performance measurement, data optimization and marketing services,
                    content providers, and our legal and financial advisors (collectively, "Third Party Service Provider(s)").
                </span>

                <p>
                    DD Zone Inc. may share the following categories of Personal Information with Third Party Service Providers for a
                    business purpose:<br/><br/>
                    <li>identifiers, including name, alias, postal address, unique personal identifier, online identifier, internet
                        protocol address, email address, account name, or other similar identifiers</li>
                    <li>commercial information, for example information regarding products or services purchased, obtained, or
                        considered</li>
                </p>
                <p>
                    4.2. our Services may enable you to share your Personal Information with third parties directly, such as via page
                    framing techniques to serve content to or from Third-Party Service Providers or other parties, while preserving
                    the look and feel of our Website and Services ("Frames"). Please be aware that if you choose to interact or share
                    any Personal Information via such Frames, you are in fact providing it to these third parties and not to us, and
                    such interactions and sharing are governed by those third parties' policies and not ours.
                </p>
                <span>You are solely responsible for notifying your Users-of-Users of the collection, processing and use of their
                    Information and informing them that their Information will be transferred to and processed by DD Zone Inc.
                    on your behalf.</span>

                <p>
                    4.3. DD Zone Inc. Subsidiaries and Affiliated Companies: We may share your Personal Information internally within
                    our family of companies, for the purposes described in this Privacy Policy. For example, we may share your Personal
                    Information with subsidiary, in the course of facilitating and providing you (and your Users-of-Users) with our
                    Services.
                </p>

                <h3>5. Where do we store your 'Personal Information'?</h3>
                <p>
                    5.1. Users and Users-of-Users Personal Information may be stored in data centers located in the United States of
                    America. We may use other jurisdictions as necessary for the proper delivery of our Services and/or as may be
                    required by law.
                </p>

                <p>
                    5.2. Third-Party Service Providers that store or process your Personal Information on DD Zone Inc.’s behalf are
                    contractually committed to keep it protected and secured, in accordance with industry standards and regardless of
                    any lesser legal requirements which may apply in their jurisdiction.
                </p>

                {/*<h3>6. Use of cookies and other third-party technologies</h3>*/}
                {/*<p>*/}
                {/*    We and our Third Party Service Providers use cookies and other similar technologies (“Cookies”) in  order for us*/}
                {/*    to provide our Service and ensure that it performs properly, to analyze our performance and marketing activities,*/}
                {/*    and to personalize your experience. You can learn more about how we use cookies and similar technologies and how*/}
                {/*    you can exercise control over them in our Cookie Policy. https://valet.ddzone.xyz/cookies-policy*/}
                {/*</p>*/}

                <h3>6. Your rights in relation to your 'Personal Information'</h3>
                <ul className="personal">
                    <li>You may request to access, receive a copy of, update, amend, delete, or limit the use of your Personal
                        Information you have stored with us. Just send us an e-mail or fill out our dedicated GDPR form. You may also
                        correct and/or update your Personal Information through your account or website settings.</li>
                    <li>You can delete your Personal Information by deleting  your entire account. We will respond to your requests
                        within a reasonable timeframe.</li>
                    <li>Before fulfilling your request, we may ask you for additional information in order to confirm your identity
                        and for security purposes. We reserve the right to charge a fee where permitted by law (e.g. if your request
                        is unfounded or excessive).</li>
                </ul>
            </div>
        </div>
    </Layout>
);

export default PrivacyPolicy
